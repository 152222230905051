<template>
  <div id="wrapp_events">
    <v-row>
      <v-col
        cols="12"
        class="py-1"
        md="6"
        v-for="(event, index) in events"
        :key="index"
      >
        <p>
          <a v-if="event.href != ''" :href="event.href" target="_blank"
            ><b>{{ event.title }}</b> <br />
            {{ event.subtitle }}
          </a>
          <b v-else
            >{{ event.title }} <br />
            {{ event.subtitle }}</b
          >
        </p>
        <hr />
        <p>
          {{ event.date }}<br />
          {{ event.address }}
        </p>
      </v-col>
      <v-col cols="12" class="py-1" md="6">
        <p class="my_color_yellow">
          <b
            >Alejandro de León ha participado en eventos de las siguientes
            instituciones o proyectos:</b
          >
        </p>
        <hr class="mb-2 my_color_yellow" />
        <img
          v-for="(logo, index) in logos"
          :key="index"
          cols="12"
          class="logo_events mr-2"
          :src="getImgUrl(logo.img_src)"
          :alt="logo.img_alt"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: "events",
  data: () => ({
    events: [
      /*{
        title: "Fundación Tatiana Pérez de Guzmán el Bueno.",
        subtitle: "",
        date: "3 de abril de 2020. 16:00 h. ",
        address: "Paseo del General Martínez Campos, 25, Madrid - España.",
        href: ""
      },
      {
        title:
          "La tecnología para escalar el impacto social: ¿necesidad o utopía?",
        subtitle: "",
        date: "2 de abril de 2020.",
        address: "",
        href: "https://www.meetup.com/es-ES/Tech-For-Good-Madrid/"
      }*/
    ],
    logos: [
      {
        img_src: "logo_01.png",
        img_alt: ""
      },
      {
        img_src: "logo_02.png",
        img_alt: "utopicus"
      },
      {
        img_src: "logo_03.png",
        img_alt: "MARSH & MCLENNAN COMPANIES"
      },
      {
        img_src: "logo_04.png",
        img_alt: "Startup grind"
      },
      {
        img_src: "logo_05.png",
        img_alt: ""
      },
      {
        img_src: "logo_06.png",
        img_alt: "Ron ritual"
      },
      {
        img_src: "logo_07.png",
        img_alt: "Fundación BBVA"
      },
      {
        img_src: "logo_08.png",
        img_alt: "CEU Unversidad San Pablo"
      },
      {
        img_src: "logo_09.png",
        img_alt: "Universidad Pontificia COMILLAS"
      },
      {
        img_src: "logo_10.png",
        img_alt: "Universidad Deusto"
      },
      {
        img_src: "logo_11.png",
        img_alt: "CUNEF"
      },
      {
        img_src: "logo_12.png",
        img_alt: "Fundación ONCE"
      },
      {
        img_src: "logo_13.png",
        img_alt: "Campus Madrid"
      },
      {
        img_src: "logo_14.png",
        img_alt: "EAE Business School"
      },
      {
        img_src: "logo_15.png",
        img_alt: "incube"
      },
      {
        img_src: "logo_16.png",
        img_alt: "LAZARUS"
      },
      {
        img_src: "logo_17.png",
        img_alt: "Fundación Caja Rural"
      },
      {
        img_src: "logo_18.png",
        img_alt: ""
      },
      {
        img_src: "logo_19.png",
        img_alt: "Social Enterprise"
      },
      {
        img_src: "logo_20.png",
        img_alt: ""
      },
      {
        img_src: "logo_21.png",
        img_alt: "Pernod Ricard"
      },
      {
        img_src: "logo_22.png",
        img_alt: ""
      },
      {
        img_src: "logo_23.png",
        img_alt: "ie Business School"
      },
      {
        img_src: "logo_24.png",
        img_alt: "EOI"
      },
    ]
  }),
  methods: {
    ...mapMutations(['changeToSecondPage']),
    getImgUrl(pic) {
      return require("../assets/logos_events/" + pic);
    }
  },
  mounted() {
    this.changeToSecondPage(true);
  },
};
</script>

<style scoped>
#wrapp_events {
  font-size: 0.9rem;
}

.logo_events {
  max-width: 40px;
  filter: grayscale(100%);
}

/* @media only screen and (max-width: 960px) {
  .event_content_paddings {
    padding: 10px 20px 120px 20px !important;
  }
} */
</style>
